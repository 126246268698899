import {
  HistoryIcon,
  Button,
  useModal,
  Modal,
  ModalBody,
  Text,
  Flex,
  InjectedModalProps,
  RuleIcon,
} from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'
import RuleModal from './RuleModal'



const Rules = () => {
  const [onPresentTransactionsModal] = useModal(<RuleModal />)
  return (
    <>
      <Button variant="text" p={0} onClick={onPresentTransactionsModal} ml="16px">
        <RuleIcon color="textSubtle" width="20px" marginLeft="10px" />
      </Button>
    </>
  )
}

export default Rules
