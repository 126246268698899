import React from 'react'
import { Modal, ModalBody, Text, InjectedModalProps, Flex } from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'
import { AutoRow } from '../../Layout/Row'

const TransactionsModal: React.FC<InjectedModalProps> = ({ onDismiss }) => {
  const { t } = useTranslation()

  const style = {
    minWidth: '340px',
    maxWidth: '80%',
  }

  const styleText = {
    margin: '6px 0',
    lineHeight: '1.8',
  }

  return (
    <Modal title={t('Stake Rules')} headerBackground="gradients.cardHeader" onDismiss={onDismiss} style={style}>
      <ModalBody>
        <>
          <Flex mb="1rem" style={{ flexDirection: 'column' }}>
            <Text style={styleText}>{t('L1: Stake 0 - 199 DAO')}</Text>
            <Text style={{ ...styleText, margin: '0 0 0 20px' }}>{t('Get 1‰ DAO Transaction Rewards.')}</Text>
          </Flex>

          <Flex mb="1rem" style={{ flexDirection: 'column' }}>
            <Text style={styleText}>{t('L2: Stake 200 - 499 DAO')}</Text>
            <Text style={{ ...styleText, margin: '0 0 0 20px' }}>{t('Get 2‰ DAO Transaction Rewards.')}</Text>
          </Flex>

          <Flex mb="1rem" style={{ flexDirection: 'column' }}>
            <Text style={styleText}>{t('L3: Stake 500 - 999 DAO')}</Text>
            <Text style={{ ...styleText, margin: '0 0 0 20px' }}>{t('Get 3‰ DAO Transaction Rewards.')}</Text>
          </Flex>

          <Flex mb="1rem" style={{ flexDirection: 'column' }}>
            <Text style={styleText}>{t('L4: Stake 1000 - 4999 DAO')}</Text>
            <Text style={{ ...styleText, margin: '0 0 0 20px' }}>{t('Get 4‰ DAO Transaction Rewards.')}</Text>
          </Flex>

          <Flex mb="1rem" style={{ flexDirection: 'column' }}>
            <Text style={styleText}>{t('L5: Stake >= 5000 DAO')}</Text>
            <Text style={{ ...styleText, margin: '0 0 0 20px' }}>{t('Get 6‰ DAO Transaction Rewards.')}</Text>
          </Flex>
        </>
      </ModalBody>
    </Modal>
  )
}

export default TransactionsModal
